<template>
  <h1 class="title">GitHub Authentication</h1>
  <h2 class="subtitle">Connect with GitHub using a personal access token</h2>
  <form v-if="stage === 1" @submit.prevent="submitForm1"><!-- Stage 1: Personal access token -->
    <div class="field">
      <label class="label">Personal access token</label>
      <div class="control has-icons-left">
        <input v-model="token" class="input" type="password" placeholder="Personal Access Token" :disabled="stage1.isLoggingIn">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="key" />
        </span>
      </div>
      <p class="help">You can create a personal access token on <a href="https://github.com/settings/tokens" target="_blank">github.com/settings/tokens</a>. It must have at least 'repo' scope.</p>
    </div>
    <div class="field" v-if="stage1.isLoginError">
      <article class="message is-danger">
        <div class="message-body">
          {{ stage1.errorMessage }}
        </div>
      </article>
    </div>
    <div class="field">
      <div class="control">
        <button type="submit" class="button is-primary" :class="{ 'is-loading': stage1.isLoggingIn }">Login</button>
      </div>
    </div>
  </form>
  <form v-else-if="stage === 2" @submit.prevent="submitForm2"><!-- Stage 2: Confirm account name -->
    <div class="field">
      <article class="message is-success">
        <div class="message-header">
          <p>Confirm Details</p>
        </div>
        <div class="message-body">
          You are connecting to GitHub using the account <span class="has-text-weight-bold">{{ username }}</span>.
        </div>
      </article>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button type="submit" class="button is-primary" :class="{ 'is-loading': stage2.isConfirming }">Confirm</button>
      </div>
      <div class="control">
        <button class="button is-link is-light" @click.prevent="cancelForm2" :disabled="stage2.isConfirming">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import GitHubService from '@/assets/js/GitHubService.js'

export default {
  name: 'GitHubLogin',
  emits: [ 'login-success' ],
  data() {
    return {
      stage: 1,
      stage1: {
        isLoggingIn: false,
        isLoginError: false,
        errorMessage: null
      },
      stage2: {
        isConfirming: false
      },
      username: null,
      token: null
    }
  },
  methods: {
    async submitForm1() {
      this.stage1.isLoginError = false;
      this.stage1.isLoggingIn = true;

      const gh = await GitHubService.getInstance();
      const authResult = await gh.setAuth(this.token);
      
      if (authResult.success) {
        this.username = authResult.user.login;
        this.stage = 2;
      } else {
        this.stage1.errorMessage = 'Token is invalid. Please try again.';
        this.stage1.isLoginError = true;
      }
      this.stage1.isLoggingIn = false;
    },
    submitForm2() {
      this.stage2.isConfirming = true;
      this.$store.commit('setGitHubLogin', { username: this.username, token: this.token });
      this.$emit('login-success');
    },
    cancelForm2() {
      this.token = null;
      this.stage = 1;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
