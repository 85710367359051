<template>
  <form>
    <div class="field">
      <label class="label">Title <span class="required">*</span></label>
      <div class="control">
        <input class="input" type="text" v-model="mutableManifest.title" @keydown.enter.prevent>
      </div>
      <p class="help is-danger" v-if="errors.titleRequired">Title is required</p>
    </div>

    <div class="field">
      <label class="label">Subtitle <span class="required">*</span></label>
      <div class="control">
        <input class="input" type="text" v-model="mutableManifest.subtitle" @keydown.enter.prevent>
      </div>
      <p class="help is-danger" v-if="errors.subtitleRequired">Subtitle is required</p>
    </div>

    <div class="field">
      <label class="label">Copyright Holder</label>
      <div class="control">
        <input class="input" type="text" v-model="mutableManifest.copyright.holder" @keydown.enter.prevent>
      </div>
    </div>

    <div class="field">
      <label class="label">Copyright License</label>
      <div class="control">
        <input class="input" type="text" v-model="mutableManifest.copyright.license" @keydown.enter.prevent>
      </div>
    </div>

    <div class="field">
      <label class="label">Copyright Notice</label>
      <div class="control">
        <input class="input" type="text" v-model="mutableManifest.copyright.notice" @keydown.enter.prevent>
      </div>
    </div>

    <div class="field">
      <label class="label">Author(s)</label>
      <div class="control">
        <textarea class="textarea" v-model.lazy="authorsMultilineString"></textarea>
      </div>
      <p class="help">Name each author on a new line</p>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-primary" :class="{ 'is-loading': isSaving }" @click.prevent="save">Save</button>
      </div>
      <div class="control">
        <button class="button is-light" @click.prevent="cancel" :disabled="isSaving">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ManifestEditor',
  props: {
    manifest: {
      type: Object,
      default() {
        // Provides the default shape for a new manifest
        return {
          "title": "",
          "subtitle": "",
          "authors": [],
          "copyright": {
              "holder": "",
              "license": "",
              "notice": ""
          },
          "chapters": []
        }
      }
    }
  },
  emits: [ 'save', 'cancel' ],
  data() {
    return {
      mutableManifest: JSON.parse(JSON.stringify(this.manifest)), // deep copy to allow for mutation
      errors: {
        titleRequired: false,
        subtitleRequired: false,
      },
      isSaving: false
    }
  },
  computed: {
    authorsMultilineString: {
      get() {
        return this.mutableManifest.authors.join('\n');
      },
      set(newVal) {
        // Split on newlines and remove empty values
        this.mutableManifest.authors = newVal.split(/\r?\n/).filter(e => !this.isEmptyOrWhiteSpace(e));
      }
    }
  },
  methods: {
    isEmptyOrWhiteSpace(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    save() {
      this.isSaving = true;
      // Input validation
      this.errors.titleRequired = this.isEmptyOrWhiteSpace(this.mutableManifest.title);
      this.errors.subtitleRequired = this.isEmptyOrWhiteSpace(this.mutableManifest.subtitle);

      // Emit save event if there are no errors
      const valid = Object.values(this.errors).every(e => e === false);
      if (valid) this.$emit('save', this.mutableManifest);
      else this.isSaving = false;
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
