<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item has-text-weight-bold is-family-serif has-text-light is-size-4" href="/-/edit">
        MO Books
      </a>

      <a role="button" class="navbar-burger" :class="{ 'is-active': navBarMenuOpen }" @click="navBarMenuOpen = !navBarMenuOpen" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu"  :class="{ 'is-active': navBarMenuOpen }">
      <div class="navbar-start">
        <router-link to="/-/edit" class="navbar-item">Home</router-link>
        <a href="#" @click.prevent="publish" class="navbar-item">Publish</a>
        <router-link to="/" class="navbar-item">View Published Book</router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-light" @click="disconnectGitHub" v-show="!requiresLogin">
              <span class="icon"><font-awesome-icon :icon="['fab', 'github']"></font-awesome-icon></span>
              <span>Disconnect</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <section class="section" v-if="!loading && requiresLogin">
    <div class="container is-max-desktop">
      <git-hub-login @login-success="handleLoginSuccess" />
    </div>
  </section>
  <router-view @auth-failed="handleAuthFailed" @connected="handleGitHubConnected" v-show="!loading && !requiresLogin" :authenticated="!requiresLogin" :connected="!requiresLogin && gitHubConnected"></router-view>
  <div class="modal" :class="{ 'is-active': publishModal.isOpen }">
    <div class="modal-background" @click="publishModalClose"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Publish your MO Books book</p>
        <button class="delete" aria-label="close" @click="publishModalClose"></button>
      </header>
      <section class="modal-card-body content">
        <p>To publish this book, place the entire contents of the GitHub repository ({{ $store.state.gitHubConnectedRepository }}) at the root of a webserver.</p>
        <p>Our recommended free provider is <a href="https://pages.cloudflare.com" target="_blank">Cloudflare Pages</a>. Simply connect your GitHub repository and leave all other configurations as default.</p>
        <p>In order to support the correct handling of the application and your book content, the webserver must be configured with a catch-all fallback rewrite to change all requests for missing files to <code>/index.html</code>.</p>
        <p>Some hosts provide this by default, for example Cloudflare Pages. Other providers require a configuration file.</p>
        <p>For example, the following <code>_redirects</code> file configures <a href="https://www.netlify.com" target="_blank">Netlify</a>:</p>
        <p><pre><code>/* /index.html 200</code></pre></p>
        <p><a href="https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations" target="_blank">View more example server configurations</a>.</p>
      </section>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import GitHubService from '@/assets/js/GitHubService.js'
import GitHubLogin from '../components/GitHubLogin.vue'

export default {
  name: 'AdminHome',
  components: {
    GitHubLogin
  },
  data() {
    return {
      loading: true,
      requiresLogin: true,
      gitHubConnected: false,
      navBarMenuOpen: false,
      publishModal: {
        isOpen: false
      }
    }
  },
  setup() {
    useMeta({
      meta: [
        { name: 'robots', content: 'noindex' } // prevent search engine crawling of the admin site
      ]
    })
  },
  methods: {
    handleAuthFailed() {
      this.requiresLogin = true;
    },
    handleLoginSuccess() {
      this.requiresLogin = false;
    },
    handleGitHubConnected() {
      this.gitHubConnected = true;
      this.loadManifest();
    },
    disconnectGitHub() {
      this.$store.dispatch('disconnectGitHub');
      window.location.href = '/-/edit';
    },
    async loadManifest() {
      const gh = await GitHubService.getInstance();
      const manifest = await gh.getManifest(this.$store.state.gitHubConnectedRepository);
      if (!manifest.exists) return;
      this.$store.commit('setAdminManifest', manifest.content);
    },
    publish() {
      this.publishModal.isOpen = true;
    },
    publishModalClose() {
      this.publishModal.isOpen = false;
    }
  },
  async created() {
    if (this.$store.state.gitHubConnectedRepository === null) {
      this.$router.replace({ name: 'Connect' });
    } else {
      this.gitHubConnected = true;
    }

    if (this.$store.state.gitHubLogin !== null) {
      this.requiresLogin = false;
    }

    if (!this.requiresLogin && this.gitHubConnected) {
      await this.loadManifest();
    }
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.is-family-serif {
  font-family: serif;
}
</style>
