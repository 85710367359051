<template>
  <section class="section">
    <div class="container is-max-desktop">
      <Transition name="slide-fade-horizontal" mode="out-in">
        <div v-if="stage === 1"><!-- Stage 1: GitHub repository -->
          <h1 class="title">Connect to a GitHub Repository</h1>
          <h2 class="subtitle">Choose the GitHub Repository to contain your book source</h2>
          <div class="has-text-weight-bold" v-if="loadingRepos">Loading...</div>
          <div class="repo-list" v-else>
            <div class="subtitle is-6">Showing repositories from account {{ gitHubUsername }}</div>
            <div class="card repo-list__card" :class="{ 'repo-list__card--active': selectedRepo.id === repo.id }" v-for="repo in repoPagination.data.repos" :key="repo.id" @click="repoCardClick(repo)">
              <header class="card-header">
                <p class="card-header-title repo-list__card-title">
                  {{ repo.full_name }} <span class="tag is-rounded ml-2">{{ repo.private ? 'private' : 'public' }}</span>
                </p>
                <div class="card-header-icon" aria-label="more options">
                  <span class="icon">
                    <font-awesome-icon icon="check" v-if="selectedRepo.id === repo.id" />
                  </span>
                </div>
              </header>
            </div>
            <div class="level mt-5">
              <div class="level-left">
                <button class="level-item button" @click="paginationChangePage(repoPagination.currentPage - 1)" :disabled="repoPagination.currentPage === 1">Previous</button>
                <button class="level-item button" @click="paginationChangePage(repoPagination.currentPage + 1)" :disabled="!repoPagination.data.hasNext">Next</button>
                <p class="level-item">Page {{ repoPagination.currentPage }}</p>
              </div>
              <div class="level-right">
                <button class="level-item button is-primary" :class="{ 'is-loading': repoPagination.isSubmitting }" :disabled="selectedRepo.id === null" @click="submitForm1">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="stage === 2"><!-- Stage 2: New manifest -->
          <h1 class="title">Create new book information</h1>
          <h2 class="subtitle"></h2>
          <manifest-editor @save="submitForm2" @cancel="cancelForm2"></manifest-editor>
        </div>
        <div v-else-if="stage === 3"><!-- Stage 3: Success -->
          <h1 class="title">Success</h1>
          <h2 class="subtitle">This repository has been successfully connected</h2>
          <p class="subtitle is-6 is-italic">{{ selectedRepo.fullName }}</p>
          <button class="button is-primary" @click="finish">Continue</button>
        </div>
      </Transition>
    </div>
  </section>
</template>

<script>
import { useMeta } from 'vue-meta'
import GitHubService from '@/assets/js/GitHubService.js'
import ManifestEditor from '@/components/ManifestEditor.vue'

export default {
  name: 'Connect',
  components: {
    ManifestEditor
  },
  props: {
    authenticated: Boolean
  },
  emits: [ 'connected' ],
  data() {
    return {
      gitHubUsername: null,
      repoPagination: { 
        data: null,
        currentPage: 1,
        isSubmitting: false
      },
      selectedRepo: {
        id: null,
        fullName: null
      },
      loadingRepos: true,
      stage: 1
    }
  },
  setup() {
    useMeta({
      title: 'Connect | MO Books'
    })
  },
  watch: {
    authenticated: {
      immediate: true,
      async handler(newVal) {
        if (newVal === true) {
          this.gitHubUsername = this.$store.state.gitHubLogin.username;
          await this.paginationChangePage(this.repoPagination.currentPage);
        } else {
          this.repoPagination.data = null;
          this.repoPagination.currentPage = 1;
          this.selectedRepo.id = null;
          this.selectedRepo.fullName = null;
          this.loadingRepos = true;
        }
      }
    }
  },
  methods: {
    repoCardClick(repo) {
      if (this.selectedRepo.id === repo.id) {
        this.selectedRepo.id = null;
        this.selectedRepo.fullName = null;
      } else {
        this.selectedRepo.id = repo.id;
        this.selectedRepo.fullName = repo.full_name;
      }
    },
    async submitForm1() {
      this.repoPagination.isSubmitting = true;
      const repoFullName = this.selectedRepo.fullName;
      const gh = await GitHubService.getInstance();
      const manifest = await gh.getManifest(repoFullName);
      if (manifest.exists) {
        console.log('Manifest found in repository. Skipping first-time setup.');
        this.setConnected(repoFullName);
      } else {
        console.log('Manifest not found in repository. Performing first-time setup.');
        this.stage = 2;
      }
      this.repoPagination.isSubmitting = false;
    },
    async submitForm2(newManifest) {
      const gh = await GitHubService.getInstance();
      const repoFullName = this.selectedRepo.fullName;
      await gh.createManifest(repoFullName, newManifest);
      this.setConnected(repoFullName);
    },
    async cancelForm2() {
      this.repoPagination.data = null;
      this.repoPagination.currentPage = 1;
      this.selectedRepo.id = null;
      this.selectedRepo.fullName = null;
      this.stage = 1;
      await this.paginationChangePage(this.repoPagination.currentPage);
    },
    async paginationChangePage(pageNum) {
      this.loadingRepos = true;
      const gh = await GitHubService.getInstance();
      this.repoPagination.data = await gh.getRepos(pageNum);
      this.repoPagination.currentPage = pageNum;
      this.loadingRepos = false;
    },
    setConnected(repoFullName) {
      this.$store.commit('setGitHubConnectedRepository', repoFullName);
      this.$emit('connected');
      this.stage = 3;
    },
    finish() {
      this.$router.replace({ name: 'EditHome' });
    }
  }
}
</script>

<style lang="scss" scoped>
.repo-list {
  &__card {
    cursor: pointer;
    transition: 0.1s;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &:hover {
      background: $white-ter;
    }

    &--active {
      background: $grey-lightest;

      &:hover {
        background: $grey-lightest;
      }
    }
  }

  &__card-title {
    display: block;
  }
}

.slide-fade-horizontal-enter-active,
.slide-fade-horizontal-leave-active {
  transition: all 0.2s ease-out;
}

.slide-fade-horizontal-enter-from {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-horizontal-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>