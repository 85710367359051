import { Octokit } from "octokit"; // GitHub SDK (https://github.com/octokit/octokit.js)
import store from '@/store'

/**
 * Provides an interface to interact with the GitHub REST API (https://docs.github.com/en/rest).
 *
 * @export
 * @class GitHubService
 */
export default class GitHubService {
  static #instance;

  constructor() {
  }

  /**
   * A converter to convert between UTF-8 and Base64.
   *
   * @memberof GitHubService
   */
  static #base64Converter = {
    encode: s => Buffer.from(s).toString('base64'),
    decode: s => Buffer.from(s, 'base64').toString()
  }

  /**
   * Get the singleton instance of this service.
   *
   * @static
   * @return {GitHubService} the singleton instance
   * @memberof GitHubService
   */
  static async getInstance() {
    if (!GitHubService.#instance) {
      const service = new GitHubService();
      // Check if GitHub credentials are already stored
      if (store.state.gitHubLogin !== null) {
        const authResult = await service.setAuth(store.state.gitHubLogin.token);
        if (authResult.success) {
          console.log('Successfully authenticated with existing GitHub credentials');
        } else {
          console.error('Failed to authenticate with existing GitHub credentials');
          store.commit('setGitHubLogin', null);
        }
      }
      GitHubService.#instance = service;
    }

    return GitHubService.#instance;
  }

  /**
   * Set and verify the user's authentication token.
   *
   * @param {string} token personal access token
   * @return {Object} {boolean success, object user, string scopes}
   * @memberof GitHubService
   */
  async setAuth(token) {
    this.octokit = new Octokit({
      auth: token,
    });

    try {
      const authenticatedUser = await this.octokit.rest.users.getAuthenticated();
      return {
        success: true,
        user: authenticatedUser.data,
        scopes: authenticatedUser.headers['x-oauth-scopes']
      };
    } catch (e) {
      console.error(e);
      return {
        success: false,
        user: null,
        scopes: null
      };
    }
  }

  /**
   * Get a list of the user's repositories (10 per page).
   *
   * @param {number} pageNum The page number (>= 1)
   * @return {?Object} {array repos, boolean hasNext} or null on failure
   * @memberof GitHubService
   */
  async getRepos(pageNum) {
    if (pageNum < 1) {
      console.error('Page number must be >= 1');
      return null;
    }

    try {
      // Sort by last updated and retrieve 10 per page
      const result = await this.octokit.request(`GET /user/repos?sort=updated&per_page=10&page=${pageNum}`);
      return {
        repos: result.data,
        hasNext: result.headers.link.includes('rel="next"')
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Get a repository.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @memberof GitHubService
   */
  async getRepo(repoFullName) {
    const result = await this.octokit.request(`GET /repos/${repoFullName}`);
    return result.data;
  }

  /**
   * Get the default branch name of a repository.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @return {string} the default branch name
   * @memberof GitHubService
   */
  async getDefaultBranchName(repoFullName) {
    const repoInfo = await this.getRepo(repoFullName);
    return repoInfo.default_branch;
  }
  
  /**
   * Get the latest commit on a branch.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} branch the branch name, e.g. "master"
   * @memberof GitHubService
   */
  async getLatestCommit(repoFullName, branch) {
    const result = await this.octokit.request(`GET /repos/${repoFullName}/commits/${branch}`);
    return result.data;
  }

  /**
   * Get an object tree.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} treeSha
   * @return {object} 
   * @memberof GitHubService
   */
  async getTree(repoFullName, treeSha) {
    const result = await this.octokit.request(`GET /repos/${repoFullName}/git/trees/${treeSha}`);
    return result.data;
  }
  
  /**
   * Get the contents of a file or directory.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} path the path to the object, e.g. "path/to/file.txt"
   * @return {?Object} {boolean exists, ?string type, (string|array|null) content} or null on failure
   * @memberof GitHubService
   */
  async getObject(repoFullName, path) {
    try {
      const result = await this.octokit.request(`GET /repos/${repoFullName}/contents/${path}`);
      if (Array.isArray(result.data)) {
        return {
          exists: true,
          type: 'dir',
          content: result.data.map(e => ({
            type: e.type,
            name: e.name
          }))
        }
      } else {
        return {
          exists: true,
          type: 'file',
          content: GitHubService.#base64Converter.decode(result.data.content)
        }
      }
    } catch (e) {
      if (e.status === 404) {
        return {
          exists: false,
          type: null,
          content: null
        }
      } else {
        console.error(e);
        return null;
      }
    }
  }

  /**
   * Delete a file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} path the path to the file, e.g. "path/to/file.txt"
   * @param {string} message the Git commit message
   * @return {Object} {boolean success}
   * @memberof GitHubService
   */
  async deleteFile(repoFullName, path, message) {
    try {
      const sha = await this.#getFileSha(repoFullName, path);
      await this.octokit.request(`DELETE /repos/${repoFullName}/contents/${path}`, {
        message: message,
        sha: sha
      });
      return {
        success: true
      }
    } catch (e) {
      if (e.status === 404) { // file already does not exist; consider this success
        return {
          success: true
        }
      } else {
        console.error(e);
        return {
          success: false
        }
      }
    }
  }

  /**
   * Create the booksrc/manifest.json file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {object} manifest the manifest object
   * @memberof GitHubService
   */
  async createManifest(repoFullName, manifest) {
    await this.createOrUpdateFile(repoFullName, 'booksrc/manifest.json', 'Create manifest.json', JSON.stringify(manifest, null, 2));
  }

  /**
   * Get the booksrc/manifest.json file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @return {?object} {boolean exists, ?string type, ?object content} or null on failure
   * @memberof GitHubService
   */
  async getManifest(repoFullName) {
    const obj = await this.getObject(repoFullName, 'booksrc/manifest.json');
    return {
      exists: obj.exists,
      type: obj.type,
      content: obj.exists ? JSON.parse(obj.content) : null
    };
  }

  /**
   * Update the booksrc/manifest.json file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {object} manifest the manifest object
   * @param {?string} [message=null] the Git commit message - defaults to 'Update manifest.json'
   * @memberof GitHubService
   */
  async updateManifest(repoFullName, manifest, message = null) {
    const path = 'booksrc/manifest.json';
    const sha = await this.#getFileSha(repoFullName, path);
    const commitMessage = message ?? 'Update manifest.json'
    await this.createOrUpdateFile(repoFullName, path, commitMessage, JSON.stringify(manifest, null, 2), sha);
  }

  /**
   * Create a chapter.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} chapterId the ID of the chapter to create
   * @memberof GitHubService
   */
  async createChapter(repoFullName, chapterId) {
    await this.createOrUpdateFile(repoFullName, `booksrc/chapters/${chapterId}.md`, 'Create chapter', '');
  }

  /**
   * Get the content of a chapter.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} chapterId the ID of the chapter to retrieve
   * @return {?string} the content of the chapter, or null if it does not exist
   * @memberof GitHubService
   */
  async getChapterContent(repoFullName, chapterId) {
    const file = await this.getObject(repoFullName, `booksrc/chapters/${chapterId}.md`);
    if (!file.exists) {
      console.error('Chapter does not exist:', chapterId);
      return null;
    } else {
      return file.content;
    }
  }

  /**
   * Update the content of a chapter.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} chapterId the ID of the chapter to update
   * @param {string} content the new content of the chapter
   * @memberof GitHubService
   */
  async updateChapterContent(repoFullName, chapterId, content) {
    const path = `booksrc/chapters/${chapterId}.md`;
    const sha = await this.#getFileSha(repoFullName, path);
    await this.createOrUpdateFile(repoFullName, path, 'Update chapter', content, sha);
  }

  /**
   * Get the SHA of a file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} path the path to the object, e.g. "path/to/file.txt"
   * @return {string} the SHA of the file
   * @memberof GitHubService
   */
   async #getFileSha(repoFullName, path) {
    const file = await this.octokit.request(`GET /repos/${repoFullName}/contents/${path}`);
    return file.data.sha;
  }

  /**
   * Create or update a file.
   *
   * @param {string} repoFullName the full name of the repository ("owner/repo")
   * @param {string} path the path to the object, e.g. "path/to/file.txt"
   * @param {string} message the Git commit message
   * @param {string} content the file content
   * @param {string} [oldSha=null] the sha of the file to update - required only when updating an existing file
   * @memberof GitHubService
   */
  async createOrUpdateFile(repoFullName, path, message, content, oldSha = null) {
    await this.octokit.request(`PUT /repos/${repoFullName}/contents/${path}`, {
      message: message,
      sha: oldSha,
      content: GitHubService.#base64Converter.encode(content)
    });
  }

  /**
   * Get a resource from an absolute URL.
   *
   * @param {string} absoluteUrl absolute resource URI
   * @memberof GitHubService
   */
  async getFromAbsoluteUrl(absoluteUrl) {
    const base = 'https://api.github.com';
    if (!absoluteUrl.startsWith(base)) console.error(`Unexpected full URL format: ${absoluteUrl}`);

    const url = absoluteUrl.slice(base.length);
    return await this.get(url);
  }

  /**
   * Get a resource.
   *
   * @param {string} url relative resource path
   * @memberof GitHubService
   */
  async get(url) {
    return await this.octokit.request(`GET ${url}`);
  }
}
