<template>
  <div v-if="connected && manifest !== null">
    <section class="section">
      <div class="container">
        <h1 class="title">Dashboard</h1>
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <div class="tile is-child card key-value">
              <header class="card-header">
                <p class="card-header-title">
                  Information
                </p>
                <button class="card-header-icon">
                  <span class="icon">
                    <font-awesome-icon icon="info"></font-awesome-icon>
                  </span>
                </button>
              </header>
              <div class="card-content">
                <div class="key">Title</div>
                <div>{{ manifest.title }}</div>
                <div class="key">Subtitle</div>
                <div>{{ manifest.subtitle }}</div>
              </div>
              <footer class="card-footer">
                <router-link :to="{ name: 'EditManifest' }" class="card-footer-item">Edit</router-link>
              </footer>
            </div>
            <div class="tile is-child card key-value">
              <header class="card-header">
                <p class="card-header-title">
                  GitHub
                </p>
                <button class="card-header-icon">
                  <span class="icon">
                    <font-awesome-icon :icon="['fab', 'github']"></font-awesome-icon>
                  </span>
                </button>
              </header>
              <div class="card-content">
                <div class="key">Account</div>
                <div>{{ gitHub.account }}</div>
                <div class="key">Repository</div>
                <div>{{ gitHub.repository }}</div>
              </div>
              <footer class="card-footer">
                <a href="#" @click.prevent="disconnectGitHub" class="card-footer-item">Disconnect</a>
              </footer>
            </div>
          </div>
          <div class="tile is-parent is-vertical">
            <div class="tile is-child card chapters">
              <header class="card-header">
                <p class="card-header-title">
                  Chapters
                </p>
                <button class="card-header-icon">
                  <span class="icon">
                    <font-awesome-icon icon="list-ol"></font-awesome-icon>
                  </span>
                </button>
              </header>
              <div class="card-content is-flex-grow-1">
                <div class="chapter-list height-100-percent">
                  <div class="chapter-list__item" v-for="chapter in manifest.chapters" :key="chapter.id">
                    <div>{{ chapter.title }}</div>
                    <div class="buttons">
                      <router-link :to="{ name: 'EditChapter', params: { id: chapter.id } }" class="button is-small is-primary is-outlined">
                        <span class="icon is-small">
                          <font-awesome-icon icon="pen-to-square" />
                        </span>
                        <span>Edit</span>
                      </router-link>
                      <button class="button is-small is-danger is-outlined" @click="deleteChapter(chapter)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash-can" />
                        </span>
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a href="#" class="card-footer-item" @click.prevent="createChapter">New Chapter</a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal" :class="{ 'is-active': deleteChapterModal.isOpen }">
      <div class="modal-background" @click="deleteChapterModalClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Chapter</p>
          <button class="delete" aria-label="close" @click="deleteChapterModalClose"></button>
        </header>
        <section class="modal-card-body">
          Are you sure you want to delete the chapter <span class="has-text-weight-bold">{{ deleteChapterModal.chapter?.title }}</span>?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" :class="{ 'is-loading': deleteChapterModal.isDeleting }" @click="deleteChapterModalDelete">Delete Chapter</button>
          <button class="button" @click="deleteChapterModalClose" :disabled="deleteChapterModal.isDeleting">Cancel</button>
        </footer>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': createChapterModal.isOpen }">
      <div class="modal-background" @click="createChapterModalClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Chapter</p>
          <button class="delete" aria-label="close" @click="createChapterModalClose"></button>
        </header>
        <section class="modal-card-body">
          <chapter-details-editor v-if="createChapterModal.isOpen" @save="createChapterModalCreate" @cancel="createChapterModalClose" />
        </section>
      </div>
    </div>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import GitHubService from '@/assets/js/GitHubService.js'
import ChapterDetailsEditor from '@/components/ChapterDetailsEditor.vue'

export default {
  name: 'EditHome',
  components: {
    ChapterDetailsEditor
  },
  props: {
    connected: Boolean
  },
  data() {
    return {
      deleteChapterModal: {
        chapter: null,
        isOpen: false,
        isDeleting: false
      },
      createChapterModal: {
        isOpen: false
      }
    }
  },
  setup() {
    useMeta({
      title: 'Home | MO Books'
    })
  },
  computed: {
    manifest() {
      return this.$store.state.adminManifest;
    },
    gitHub() {
      const connected = this.$store.state.gitHubConnectedRepository;
      const parts = connected.split('/');
      return {
        account: parts[0],
        repository: parts[1]
      }
    }
  },
  methods: {
    disconnectGitHub() {
      this.$store.dispatch('disconnectGitHub');
      window.location.href = '/-/edit';
    },
    deleteChapter(chapter) {
      this.deleteChapterModal.chapter = chapter;
      this.deleteChapterModal.isOpen = true;
    },
    deleteChapterModalClose() {
      this.deleteChapterModal.isOpen = false;
      this.deleteChapterModal.chapter = null;
      this.deleteChapterModal.isDeleting = false;
    },
    async deleteChapterModalDelete() {
      this.deleteChapterModal.isDeleting = true;
      const gh = await GitHubService.getInstance();
      const manifest = this.$store.state.adminManifest;
      const chapterIndex = manifest.chapters.findIndex(chapter => chapter.id === this.deleteChapterModal.chapter.id);
      if (chapterIndex === -1) {
        console.error('Chapter not found');
        return;
      }
      manifest.chapters.splice(chapterIndex, 1);
      await gh.updateManifest(this.$store.state.gitHubConnectedRepository, manifest, 'Delete chapter details');
      await gh.deleteFile(this.$store.state.gitHubConnectedRepository, `booksrc/chapters/${this.deleteChapterModal.chapter.id}.md`, 'Delete chapter');
      this.deleteChapterModalClose();
    },
    createChapter() {
      this.createChapterModal.isOpen = true;
    },
    createChapterModalClose() {
      this.createChapterModal.isOpen = false;
    },
    async createChapterModalCreate(newChapter) {
      const gh = await GitHubService.getInstance();
      await gh.createChapter(this.$store.state.gitHubConnectedRepository, newChapter.id);
      const manifest = this.$store.state.adminManifest;
      manifest.chapters.push(newChapter);
      await gh.updateManifest(this.$store.state.gitHubConnectedRepository, manifest, 'Create chapter details');
      this.createChapterModalClose();
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .card-header-icon {
    cursor: default;
  }

  &.key-value .card-content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 1rem;
    grid-auto-flow: row;

    .key {
      justify-self: end;
      font-weight: bold;
    }
  }
}

.tile.is-child {
  display: flex;
  flex-direction: column;

  footer {
    margin-top: auto;
  }
}

.chapter-list {
  min-height: 5rem;
  max-height: 35rem;
  overflow-y: auto;

  &__item {
    display: flex;

    &:nth-child(odd) {
      background-color: $white-bis;
    }

    & > * {
      padding: 0.75rem 1rem;

      &:first-child {
        flex-grow: 1;
      }
    }

    .buttons {
      display: block;
      white-space: nowrap;
    }
  }
}
</style>