<template>
  <div class="section">
    <div class="container">
      <h1 class="title">Edit Book Information</h1>
      <manifest-editor v-if="manifest !== null" :manifest="manifest" @save="onSave" @cancel="onCancel"></manifest-editor>
      <div v-else>Loading...</div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import GitHubService from '@/assets/js/GitHubService.js'
import ManifestEditor from '@/components/ManifestEditor.vue'

export default {
  name: 'EditManifest',
  components: {
    ManifestEditor
  },
  data() {
    return {
    }
  },
  setup() {
    useMeta({
      title: 'Edit Book Information | MO Books'
    })
  },
  computed: {
    manifest() {
      return this.$store.state.adminManifest;
    },
  },
  created() {
  },
  methods: {
    async onSave(newManifest) {
      const gh = await GitHubService.getInstance();
      await gh.updateManifest(this.$store.state.gitHubConnectedRepository, newManifest);
      this.$store.commit('setAdminManifest', newManifest);
      this.$router.push({ name: 'EditHome' });
    },
    onCancel() {
      this.$router.push({ name: 'EditHome' });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
