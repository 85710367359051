<template>
  <div>
    <router-view :connected="connected"></router-view>
  </div>
</template>

<script>
export default {
  name: 'Edit',
  components: {
  },
  props: {
    connected: Boolean
  },
  data() {
    return { }
  },
}
</script>

<style lang="scss" scoped>

</style>