<template>
  <div class="section">
    <div class="container">
      <h1 class="title">Edit Chapter</h1>
      <div class="box" v-if="manifest !== null">
        <div class="columns">
          <div class="column key-value">
            <div class="key">Slug</div>
            <div>{{ chapter.slug }}</div>
            <div class="key">Title</div>
            <div>{{ chapter.title }}</div>
            <div class="key">Subtitle</div>
            <div>{{ chapter.subtitle }}</div>
          </div>
          <div class="column is-narrow"><button class="button" @click="editDetails">Edit</button></div>
        </div>
      </div>
      <form>
        <div class="field">
          <label class="label">Content</label>
          <div class="control">
            <textarea class="textarea" rows="30" v-model="workingContent" :disabled="workingContent === null"></textarea>
          </div>
        </div>

        <div class="field is-grouped">
          <div class="control">
            <button class="button is-primary" :class="{ 'is-loading': isSaving }" @click.prevent="save" :disabled="!contentChanged">Save</button>
          </div>
          <div class="control">
            <button class="button is-light" @click.prevent="cancel" :disabled="isSaving">Cancel</button>
          </div>
          <div class="control">
            <button class="button" @click.prevent="preview" :disabled="isSaving">Preview</button>
          </div>
          <div class="control">
            <button class="button" @click.prevent="showSyntax" :disabled="isSaving">Syntax</button>
          </div>
          <p class="help is-success" v-if="saveSuccess">Successfully saved</p>
        </div>
      </form>
    </div>
    <div class="modal" :class="{ 'is-active': editDetailsModal.isOpen }">
      <div class="modal-background" @click="editDetailsModalClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Chapter Details</p>
          <button class="delete" aria-label="close" @click="editDetailsModalClose"></button>
        </header>
        <section class="modal-card-body">
          <chapter-details-editor v-if="editDetailsModal.isOpen" :chapter="editDetailsModal.chapter" @save="editDetailsModalSave" @cancel="editDetailsModalClose" />
        </section>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': previewModal.isOpen }">
      <div class="modal-background" @click="previewModalClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Preview Chapter</p>
          <button class="delete" aria-label="close" @click="previewModalClose"></button>
        </header>
        <section class="modal-card-body">
          <markdown-viewer v-if="previewModal.isOpen" :markdown="workingContent" isAdminPreview />
        </section>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': syntaxModal.isOpen }">
      <div class="modal-background" @click="syntaxModalClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">MO Books Extended Markdown Syntax</p>
          <button class="delete" aria-label="close" @click="syntaxModalClose"></button>
        </header>
        <section class="modal-card-body content">
          <h5>Markdown</h5>
          <p>MO Books supports an extended version of Markdown. Markdown is a lightweight markup language that can create powerful content with an easy-to-learn syntax.</p>
          <h6>Basic Syntax</h6>
          <p>Markdown's basic syntax can be found on <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">markdownguide.org/basic-syntax</a>.</p>
          <h6>Extended Syntax</h6>
          <p>To make your books more maintainable, additional extended syntax is supported:</p>
          <dl>
            <dt>Code References</dt>
            <dd>
              Easily include code snippets in your book by referencng a code file that is loaded directly with effortless syntax highlighting - <code>!{URL}</code>.<br>
              For example: <code>!{/booksrc/path/to/code.py}</code> or <br><code>!{https://example.com/path/to/code.py}</code>.
            </dd>
          </dl>
          <h6>Resources</h6>
          <p>Code files and images can be stored anywhere they are accessible on the web. If you wish to store them alongside the book for easy publishing, upload them to the GitHub repository (<a :href="`https://github.com/${$store.state.gitHubConnectedRepository}`"  target="_blank">{{ $store.state.gitHubConnectedRepository }}</a>) using either the browser or the command line (<a href="https://docs.github.com/en/repositories/working-with-files/managing-files/adding-a-file-to-a-repository" target="_blank">view instructions</a>).</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import GitHubService from '@/assets/js/GitHubService.js'
import ChapterDetailsEditor from '@/components/ChapterDetailsEditor.vue'
import MarkdownViewer from '@/components/MarkdownViewer.vue'

export default {
  name: 'EditChapter',
  components: {
    ChapterDetailsEditor,
    MarkdownViewer
  },
  data() {
    return {
      actualContent: null,
      workingContent: null,
      contentChanged: false,
      isSaving: false,
      saveSuccess: false,
      editDetailsModal: {
        isOpen: false,
        chapter: null
      },
      previewModal: {
        isOpen: false
      },
      syntaxModal: {
        isOpen: false
      }
    }
  },
  setup() {
    useMeta({
      title: 'Edit Chapter | MO Books'
    })
  },
  computed: {
    manifest() {
      return this.$store.state.adminManifest;
    },
    chapter() {
      return this.$store.state.adminManifest?.chapters.find(c => c.id === this.$route.params.id);
    }
  },
  async created() {
    await this.loadContent(this.$route.params.id);
  },
  watch: {
    workingContent(newVal, oldVal) {
      this.saveSuccess = false;
      this.contentChanged = oldVal !== null && newVal !== this.actualContent;
    }
  },
  methods: {
    async loadContent(id) {
      const gh = await GitHubService.getInstance();
      const content = await gh.getChapterContent(this.$store.state.gitHubConnectedRepository, id);
      this.actualContent = content;
      this.workingContent = content;
    },
    async save() {
      this.saveSuccess = false;
      this.isSaving = true;
      const gh = await GitHubService.getInstance();
      await gh.updateChapterContent(this.$store.state.gitHubConnectedRepository, this.chapter.id, this.workingContent);
      this.actualContent = this.workingContent;
      this.contentChanged = false;
      this.saveSuccess = true;
      this.isSaving = false;
    },
    cancel() {
      this.$router.push({ name: 'EditHome' });
    },
    preview() {
      this.previewModal.isOpen = true;
    },
    previewModalClose() {
      this.previewModal.isOpen = false;
    },
    showSyntax() {
      this.syntaxModal.isOpen = true;
    },
    syntaxModalClose() {
      this.syntaxModal.isOpen = false;
    },
    editDetails() {
      this.editDetailsModal.chapter = this.chapter;
      this.editDetailsModal.isOpen = true;
    },
    editDetailsModalClose() {
      this.editDetailsModal.isOpen = false;
      this.editDetailsModal.chapter = null;
    },
    async editDetailsModalSave(newChapter) {
      const gh = await GitHubService.getInstance();
      const manifest = this.$store.state.adminManifest;
      const index = manifest.chapters.findIndex(c => c.id === this.$route.params.id);
      manifest.chapters[index] = newChapter;
      await gh.updateManifest(this.$store.state.gitHubConnectedRepository, manifest, 'Update chapter details');
      this.editDetailsModalClose();
    }
  },
  async beforeRouteUpdate(to) {
    await this.loadContent(to.params.id);
  }
}
</script>

<style lang="scss" scoped>
.key-value {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 1rem;
  grid-auto-flow: row;

  .key {
    justify-self: end;
    font-weight: bold;
  }
}
</style>
