<template>
  <section class="hero">
      <div class="hero-body has-text-centered">
        <h1 class="title">404</h1>
        <h2 class="subtitle">Page Not Found</h2>
        <router-link to="/">Go Home</router-link>
      </div>
  </section>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: '404NotFound',
  setup() {
    useMeta({
      title: 'Page Not Found | MO Books'
    })
  },
}
</script>

<style lang="scss" scoped>

</style>