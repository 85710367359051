<template>
  <form>
    <fieldset disabled>
      <div class="field">
        <label class="label">ID</label>
        <div class="control">
          <input class="input" type="text" v-model="mutableChapter.id" @keydown.enter.prevent>
        </div>
      </div>
    </fieldset>

    <div class="field">
      <label class="label">Slug <span class="required">*</span></label>
      <div class="control">
        <input class="input" type="text" v-model="mutableChapter.slug" @keydown.enter.prevent>
      </div>
      <p class="help">This is the URL path when viewing the chapter.</p>
      <p class="help is-danger" v-if="errors.slugRequired">Slug is required</p>
      <p class="help is-danger" v-if="errors.slugInvalidChars">Slug contains invalid characters (only a-z, 0-9, and hyphens are allowed)</p>
      <p class="help is-danger" v-if="errors.slugTaken">Slug is already in use</p>
    </div>

    <div class="field">
      <label class="label">Title <span class="required">*</span></label>
      <div class="control">
        <input class="input" type="text" v-model="mutableChapter.title" @keydown.enter.prevent>
      </div>
      <p class="help is-danger" v-if="errors.titleRequired">Title is required</p>
    </div>

    <div class="field">
      <label class="label">Subtitle <span class="required">*</span></label>
      <div class="control">
        <input class="input" type="text" v-model="mutableChapter.subtitle" @keydown.enter.prevent>
      </div>
      <p class="help is-danger" v-if="errors.subtitleRequired">Subtitle is required</p>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-primary" :class="{ 'is-loading': isSaving }" @click.prevent="save">Save</button>
      </div>
      <div class="control">
        <button class="button is-light" @click.prevent="cancel" :disabled="isSaving">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ChapterDetailsEditor',
  props: {
    chapter: {
      type: Object,
      default() {
        // Provides the default shape for a new chapter
        return {
          "id": uuidv4(), // generate a UUIDv4 to uniquely identify the new chapter
          "slug": "",
          "title": "",
          "subtitle": ""
        }
      }
    }
  },
  emits: [ 'save', 'cancel' ],
  data() {
    return {
      mutableChapter: JSON.parse(JSON.stringify(this.chapter)), // deep copy to allow for mutation
      errors: {
        slugRequired: false,
        slugInvalidChars: false,
        slugTaken: false,
        titleRequired: false,
        subtitleRequired: false,
      },
      isSaving: false
    }
  },
  methods: {
    isSlugTaken(slug) {
      if (slug === '-') return true; // reserved for admin area
      if (slug !== this.chapter.slug) {
        // Slug is new or updated
        const currentChapters = this.$store.state.adminManifest.chapters;
        const sameSlugExists = currentChapters.some(c => c.slug === slug);
        return sameSlugExists;
      }
      return false;
    },
    isInvalidSlugChars(slug) {
      const validSlugRegex = /^[a-z0-9-]{1,100}$/;
      return !validSlugRegex.test(slug);
    },
    isEmptyOrWhiteSpace(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    save() {
      this.isSaving = true;
      // Input validation
      this.errors.slugRequired = this.isEmptyOrWhiteSpace(this.mutableChapter.slug);
      this.errors.slugInvalidChars = this.isInvalidSlugChars(this.mutableChapter.slug);
      this.errors.slugTaken = this.isSlugTaken(this.mutableChapter.slug);
      this.errors.titleRequired = this.isEmptyOrWhiteSpace(this.mutableChapter.title);
      this.errors.subtitleRequired = this.isEmptyOrWhiteSpace(this.mutableChapter.subtitle);

      // Emit save event if there are no errors
      const valid = Object.values(this.errors).every(e => e === false);
      if (valid) this.$emit('save', this.mutableChapter);
      else this.isSaving = false;
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
